<!-- 调价列表-查看 -->
<template>
  <div class='info' v-loading="loading">
    <back />
    <div class="table">
      <div class="title">调价信息</div>
      <div class="form">
        <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
          <el-row class="dialog_row ">
            <el-col :span="8">
              <!-- <el-form-item class="item" label="商品编号" prop="serialId">
                <el-input v-model="ruleForm.serialId" readonly></el-input>
              </el-form-item> -->
              <el-form-item class="item" label="商品条形码" prop="barCode">
                <el-input v-model="ruleForm.barCode" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="规格" prop="specs">
                <el-input v-model="ruleForm.specs" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="申请来源" prop="xxx">
                <el-input v-model="ruleForm.xxx" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="审核时间" prop="auditTime"
                v-if="ruleForm.statusDesc && ruleForm.statusDesc != '待审核'">
                <el-input v-model="ruleForm.auditTime" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="item" label="商品名称" prop="productName">
                <el-input v-model="ruleForm.productName" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="招标价格" prop="oldPrice">
                <el-input v-model="ruleForm.oldPrice" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="申请时间" prop="createdTime">
                <el-input v-model="ruleForm.createdTime" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="item" label="商品分类" prop="categoryName">
                <el-input v-model="ruleForm.categoryName" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="新价格" prop="newPrice">
                <el-input v-model="ruleForm.newPrice" readonly></el-input>
              </el-form-item>
              <el-form-item class="item" label="审核状态" prop="statusDesc">
                <el-input v-model="ruleForm.statusDesc" readonly
                  :class="{ 'red': ruleForm.statusDesc === '待审核' }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="dialog_row ">
            <el-col :span="24">
              <el-form-item class="item" label="调价原因" prop="adjustReason">
                <el-input v-model="ruleForm.adjustReason" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="dialog_row unpass " v-if="ruleForm.statusDesc == '审核不通过'">
            <el-col :span="24">
              <el-form-item class="item" label="不通过原因" prop="refuseReason">
                <el-input v-model="ruleForm.refuseReason" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      ruleForm: {},
      id: '',
      loading: false,
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.queryMySubscribeDetail();
  },
  methods: {
    // 查看详情
    queryMySubscribeDetail() {
      this.loading = true;
      this.$axios.get(this.$api.queryMySubscribeDetail, {
        params: {
          id: this.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result.result;
          // 判断状态并设置对应的状态描述
          switch (this.ruleForm.status) {
            case 0:
              this.ruleForm.statusDesc = '待审核';
              break;
            case 1:
              this.ruleForm.statusDesc = '审核通过';
              break;
            case 2:
              this.ruleForm.statusDesc = '审核不通过';
              break;
            default:
              this.ruleForm.statusDesc = '未知状态';
          }
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.dialog_row {
  /deep/ .el-form-item {
    margin-right: 60px;
  }

  /deep/.el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  /deep/ .el-input__inner {
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgba(3, 16, 14, 0.10);
  }
}

.info {
  .table {
    overflow: hidden;

    .title {
      height: 45px;
      line-height: 45px;
      padding-left: 30px;
      color: #1A1A1A;
      font-weight: bold;
    }

    .form {
      padding: 30px 0 30px 30px;
    }

    .item {
      padding-right: 50px;
    }

    .red {
      /deep/.el-input__inner {
        color: #EB2500;
      }
    }

    .unpass {

      /deep/.el-form-item__label,
      /deep/.el-input__inner {
        color: #EB2500;
      }
    }
  }
}
</style>